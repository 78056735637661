import React from 'react';
import CurrentUserDetail from '../../model/CurrentUserDetail';
import Organizacia from '../../model/Organizacia';

export interface SecurityContextContent {
  user: CurrentUserDetail;
  logged: boolean;
  accessToken?: string;
  logIn: () => void;
  logOut: () => void;
  loginUrl?: string;
  logoutUrl?: string,
  initialized?: boolean,
  organizationId?: string,
  setOrganizationId?: (string) => void,
  roles?: string[],
  organizations?: Organizacia[],
}

export default React.createContext<SecurityContextContent>({} as SecurityContextContent);
