import React from 'react';

export interface SuccessMessageContextType {
  messages?: Array<string>;

  displaySuccess(message: string): void;

  clearMessages(): void;

  clearMessage(message: string): void;
}

export default React.createContext<SuccessMessageContextType>({
  displaySuccess: undefined, clearMessages: undefined, clearMessage: undefined,
});
