import React from 'react';
import { ClientStatus } from '../../../model/ClientStatus';

export interface ClientStatusContextType {
  status?: ClientStatus | null;
  handleStatus(status: ClientStatus): void;
  clearStatus(): void;
  clearGeneralErrorMessage(errorMessage: string): void;
}

export default React.createContext<ClientStatusContextType>({
  handleStatus: () => undefined, clearStatus: () => undefined, clearGeneralErrorMessage: () => undefined,
});
